<template lang="html">

  <section class="cookies-policy">
    <div class="container text-legals">
      <h1 class="title">{{$t('menus.footer.cookiesPolicy')}}</h1>
      <article v-html="$t('legalTexts.cookiesPolicy')"></article>
    </div>
  </section>

</template>

<script lang="js">

  export default {
    name: 'cookies-policy',
    props: [],
    mounted() {

    },
    data() {
      return {

      }
    },
    methods: {

    },
    computed: {

    }
  }


</script>

<style scoped lang="scss">
  .cookies-policy {}
</style>